.SuccessIcon{
    width: 18px;
    height: auto;
    margin: 5px;
}
.Icon {
    width: 24px;
    height: auto;
    margin: 5px;
    cursor: pointer;
}
.NoteText {
    font-size: 14px;
    color: var(--color-light-text);
    margin-top: 10px;
}
.AdditionText{
    font-family: "Roboto-Medium";
    font-size: 15px;
    color: var(--color-dark-grey);
}