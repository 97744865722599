.Container {
    min-height: 100vh;
}
.Loader {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.8) 0% 0% no-repeat padding-box;
    z-index: 1;
    width: 100%;
    height: 100%;
}
/* .Banner{

} */
.BannerImageContainer{
    height: 120px;
}
.BannerImageContainer img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 4px;
}
.BannerTitle{
    margin-top: 5px;
    margin-right: 10px;
    font-size: 24px;
    color: var(--color-dark-blue);
    font-weight: bold;
    letter-spacing: 0.3px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.BannerDesc {
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    color: var(--color-dark-grey);
}
.BannerText {
    font-size: 14px;
    color: var(--color-grey);
}
.ToggleStyle {
    cursor: pointer;
}
.ToggleCheckInput:checked + .ToggleStyle {
    /* background-image: linear-gradient(180deg, #ff00b7 0%, #ff7f5e 100%) !important; */
    background-color: var(--color-active) !important;
  }