/* .PositiveSentiment {
  list-style-image: url("../../../assets/images/thumbs-up.png");
}

.NegativeSentiment {
  list-style-image: url("../../../assets/images/thumb-down.png");
}
.NeutralSentiment {
  list-style-image: url("../../../assets/images/thumb-neutral.png");
} */

.IconImage {
  margin-right: 10px;
  margin-bottom: 3px;
  width: 18px;
  height: 18px;
}
.TextStatusGood {
  font-family: "Roboto-Medium";
  color: #23d160;
  font-weight: 500;
}
.TextStatusBad {
  font-family: "Roboto-Medium";
  color: #ff3860;
  font-weight: 500;
}
.TextStatusNeutral {
  font-family: "Roboto-Medium";
  color: #ffdd57; /*rgb(237,149,33) */
  font-weight: 500;
}
