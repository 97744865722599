.TopupInput{
    border-radius: 0px !important;
    width: 100px;
}

.TopupTable th{
    color : var(--color-grey44)
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.IncreaseDecreaseButton {
    background-color: var(--color-white);
    border: 1px solid var(--border-color);
    color: var(--color-orange);
    font-family: "Roboto-Medium";
    border-radius: 4px;
    padding: 5px 10px;
    /* margin-right: 5px; */
}

.Left {
    border-radius: 4px 0px 0px 4px;
    border-right: none;
    padding: 3px 8px;
}

.Right {
    border-radius: 0px 4px 4px 0px;
    border-left: none;
    padding: 3px 8px;
}

.IncreaseDecreaseButton:hover {
    background-color: var(--color-orange);
    color : var(--color-white);
}

.IncreaseDecreaseButton:disabled {
    background-color: var(--border-color);
    color: var(--color-darker-grey);
}

@media only screen and (max-width: 576px) {
    .TopupInput{
        width: 50px;
    }
    .Amount {
        width : 130px !important
    }
}