/* .NotificationModelCardOld{
    position: fixed;
    z-index: 2001;
    top: 0;
    right: 0;
    margin-top: 56px;
    margin-right: 10px;
    font-size: 16px !important;
} */
.NotificationModelCard {
    position: fixed;
    top: 0;
    right: 0;
    margin-top: 72px;
    margin-right: 50px;
    z-index: 1;
    animation-name: MoveDown;
    animation-duration: 0.3s;
    animation-iteration-count: 1;
  }

.NotificationModelCardTitle{
    font-family: "Roboto-Medium" !important;
    font-weight: 500 !important;
    color: var(--color-darker-blue) !important;
    font-size: 20px !important;
}

.NotificationBodyContent{
    height: 50vh !important;
    overflow-y: auto;
}

.NotificationBodyContent::-webkit-scrollbar{
    width: 5px;
}
.NotificationBodyContent::-webkit-scrollbar-thumb {
    background: var(--border-color); 
  }

  @media only screen and (max-width: 576px) {
    .NotificationModelCard {
        margin-right: 20px;
      }
  }

  
@keyframes MoveDown {
    from {
      opacity: 0.8;
      margin-top: 50px;
    }
    to {
      opacity: 1;
      margin-top: 72px;
    }
  }