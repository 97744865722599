.Container {
  display: flex;
  padding: 40px 35px;
}
.FeedbackImage {
  width: 320px;
  height: auto;
  align-self: center;
}

.TextArea{
  margin-bottom: 25px;
}
.ContainerContent {
  display: flex;
  flex-direction: column;
  padding-left: 60px;
}
.Heading {
  font-size: 36px;
  margin-bottom: 10px;
}
.SubText {
  font-size: 15px;
  margin-bottom: 45px;
}
.Select {
  margin-bottom: 24px;
}
.ButtonGroup {
  display: flex;
}
.PrimaryButton {
  background-image: linear-gradient(90deg, #ff00b7 0%, #ff7f5e 100%) !important;
  border-radius: 6px;
  color: #ffffff !important;
  /* margin-left: 20px; */
}
.LoaderSmallSize {
  width: 36px;
  height: 36px;
  margin: 4px 8px;
}

@media only screen and (max-width: 768px) {
  .Container {
    padding: 20px;
  }
  .FeedbackImage {
    width: 200px;
  }
  .ContainerContent {
    padding-left: 0px;
  }
  .Heading {
    font-size: 26px;
  }
  .SubText {
    font-size: 14px;
  }
  .PrimaryButton {
    width: 41.66666667%;
  }
}
@media only screen and (min-width: 768px) {
  .FeedbackImage {
    width: 250px;
  }
  .ContainerContent {
    padding-left: 0px;
  }
  .Heading {
    font-size: 32px;
  }
  .SubText {
    font-size: 15px;
  }
  .PrimaryButton {
    width: 33.33333333%;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 992px) {
  .FeedbackImage {
    width: 320px;
  }
  .ContainerContent {
    padding-left: 60px;
  }
  .Heading {
    font-size: 36px;
  }
  .SubText {
    font-size: 15px;
  }
  .PrimaryButton {
    width: 33.33333333%;
    margin-left: 20px;
  }
}
