.Switch {
  position: relative;
  display: inline-block;
  width: 42px;
  height: 24px;
  padding-left: 20px;
}

.Switch .CheckInput {
  opacity: 0;
  width: 0;
  height: 0;
}

.Slider {
  position: absolute;
  /* cursor: pointer; */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.Slider::before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.CheckInput:checked + .Slider {
  background-color: var(--color-primary);
}

.CheckInput:focus + .Slider {
  box-shadow: 0 0 1px var(--color-primary);
}

.CheckInput:checked + .Slider::before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}

/* Rounded Sliders */
.Slider.Round {
  border-radius: 34px;
}

.Slider.Round::before {
  border-radius: 50%;
}
