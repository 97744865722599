.SearchInput {
    border-right: 0px solid transparent !important;
    border-radius: 6px 0px 0px 6px;
    padding: 10px 15px;
    font-size: 14px;
  }
  .SearchInput:focus {
    box-shadow: none;
    border: 1px solid #ced4da;
  }
  .SearchIcon {
    background-color: #fff !important;
    padding-right: 20px;
    border-radius: 0px 6px 6px 0px;
    border-left: 0px solid transparent !important;
  }

  .DropButton{
    border-color: transparent;
    background-color: var(--color-hint-grey) !important;
    border-radius: 6px 0px 0px 6px;
    padding: 10px 15px;
    font-size: 14px;
    z-index:unset !important;
  }
  
  .DropButton:hover{
    border-color: transparent;
    background-color: var(--color-grey) !important;
  }
  
  .DropMenu {
    margin-top: 5px !important;
    max-height: 250px;
    overflow-y: auto;
    border-radius: 6px;
    box-shadow: 0px 10px 20px #00000029;
    border: none;
  }
  
  .DropItem {
    font-size: 14px;
  }
  
  .DropItem:active{
    background-color: var(--border-color);
  }
  
  .DropMenu::-webkit-scrollbar{
    width: 5px;
  }
  .DropMenu::-webkit-scrollbar-track {
    border-radius: 2px;
    background-color: var(--border-color);
  }
  .DropMenu::-webkit-scrollbar-thumb {
    background: var(--color-light-grey); 
    border-radius: 2px;
  }
  .DropMenu::-webkit-scrollbar-thumb:hover {
    background: var(--color-blue-grey); 
  }