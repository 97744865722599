.AudioPlayerContainer {
  height: 48px;
  background: transparent linear-gradient(90deg, var(--color-primary) 0%, var(--color-secondary) 100%) 0% 0%
    no-repeat padding-box;
  color: white !important;
  font-size: 12px;
  display: flex;
  /* width: 300px; */
  width: 100%;
  align-items: center;
  /* justify-content: space-around; */
  border-radius: 5px;
}
.MaximizedLight,
.MaximizedDark{
  height: -webkit-fill-available;
  background: none;
}
.MaximizedLight{
  color: var(--color-dark-blue) !important;
}
.ControllerIcons {
  cursor: pointer;
  color: var(--color-white) !important;
  margin: 0px 3px;
}

.AudioPlayerContainer input[type="range"] {
  -webkit-appearance: none;
  background-color: transparent;
  padding-bottom: 4px;
  padding-left: 5px;
  padding-right: 5px;
  cursor: pointer;
}
.AudioPlayerContainer input[type="range"]::-webkit-slider-runnable-track {
  /* width: 00px; */
  width: 100%;
  height: 2px;
  background: #ddd;
  border: none;
}
.MaximizedLight input[type="range"]::-webkit-slider-runnable-track {
  background: var(--color-dark-grey);
}
.AudioPlayerContainer input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: white;
  margin-top: -4px;
}
.MaximizedLight input[type="range"]::-webkit-slider-thumb {
  background: var(--color-dark-grey);
}

.AudioPlayerContainer input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #ccc;
}
.MaximizedLight input[type="range"]:focus::-webkit-slider-runnable-track {
  background: var(--color-blue-grey);
}
.AudioPlayerContainer input[type="range"]:focus {
  outline: none;
}

.FileName {
  font-size: 18px;
}

.PreviewActionButton {
  background-color: transparent;
  border: none;
  font-size: 48px;
}

.PreviewActionIcons {
  border: 1px solid var(--border-color);
  padding: 5px;
  border-radius: 100px;
  transition: 0.2s;
  background-color: var(--color-white);
  color: var(--color-primary);
  font-size: 150px;
  padding: 0px;
}
.PreviewActionIconsAnimation {
  animation-name: SpreadShadowAnimation;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
}
@keyframes SpreadShadowAnimation {
  0% { box-shadow: -12px 0 rgba(151, 151, 151, 0.3), 0 18px rgba(151, 151, 151, 0.3), 12px 0 rgba(151, 151, 151, 0.3), 0 -18px rgba(151, 151, 151, 0.3); }
  20% { box-shadow: -14px 0 rgba(151, 151, 151, 0.3), 0 16px rgba(151, 151, 151, 0.3), 14px 0 rgba(151, 151, 151, 0.3), 0 -16px rgba(151, 151, 151, 0.3); }
  40% { box-shadow: -16px 0 rgba(151, 151, 151, 0.3), 0 14px rgba(151, 151, 151, 0.3), 16px 0 rgba(151, 151, 151, 0.3), 0 -14px rgba(151, 151, 151, 0.3); }
  60% { box-shadow: -18px 0 rgba(151, 151, 151, 0.3), 0 12px rgba(151, 151, 151, 0.3), 18px 0 rgba(151, 151, 151, 0.3), 0 -12px rgba(151, 151, 151, 0.3); }
  80% { box-shadow: -16px 0 rgba(151, 151, 151, 0.3), 0 14px rgba(151, 151, 151, 0.3), 16px 0 rgba(151, 151, 151, 0.3), 0 -14px rgba(151, 151, 151, 0.3); }
  100% { box-shadow: -14px 0 rgba(151, 151, 151, 0.3), 0 16px rgba(151, 151, 151, 0.3), 14px 0 rgba(151, 151, 151, 0.3), 0 -16px rgba(151, 151, 151, 0.3); }

}
.PreviewActionIcons:hover{
  transform: scale(1.02);
}
.PreviewActionButton:disabled .PreviewActionIcons{
    background-color: var(--color-disable-button-back) !important;
    color: var(--color-disbale-button-text) !important;
    transform: scale(1);
}
.PreviewActionButton:active .PreviewActionIcons{
    transform: scale(0.95);
}

@media only screen and (max-width: 768px){
  .FileName{
    font-size: 14px;
  }
}