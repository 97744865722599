.ThanksNoteContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px 300px;
  text-align: center;
}
.DoneImage {
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
}
.Heading {
  margin-bottom: 10px;
}
.SubText {
  margin-bottom: 45px;
}

@media only screen and (max-width: 768px) {
  .ThanksNoteContainer {
    padding: 100px 30px;
  }
}
@media only screen and (min-width: 768px) {
  .ThanksNoteContainer {
    padding: 100px 60px;
  }
}
@media only screen and (min-width: 992px) {
  .ThanksNoteContainer {
    padding: 100px 300px;
  }
}
