.TryForFreeBack {
  /* background-image: url("../../assets/images/login/vivas-ai-login-signup.png");
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; */
}
.AuthModal {
  /* background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 10px #00000029;
  border-radius: 8px;
  opacity: 1; */
  /* display: flex;
  flex-direction: column; */
}
.AuthLogo {
  width: 76px;
  align-items: center;
  text-align: center;
}
.AuthFormButton {
  font-family: "GoogleSans";
  letter-spacing: 0.5px;
  border: none;
}
.AuthFormButton:hover {
  border: none;
}
.SigninText {
  color: var(--color-light-grey);
  font-size: 14px;
}
.SigninText span {
  color: var(--color-primary);
  cursor: pointer;
  opacity: 2;
  font-family: 'Roboto-Medium';
}
.SigninText a {
  color: var(--color-primary);
}
.Loading {
  background: rgba(255, 255, 255, 0.8) 0% 0% no-repeat padding-box;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2000;
  position: absolute !important;
}

@media only screen and (max-width: 576px) {
  .TryForFreeBack {
    justify-content: center;
    align-items: flex-start;
  }
  .AuthModal {
    /* display: flex;
    flex-direction: column;
    border-radius: 0px;
    overflow-y: auto;
    width: 100vw;
    height: 100vh; */
  }
}
