.Table {
  border: 1px solid #dee2e6;
}
.TableHeadRow {
  background-color: #fcf3f1;
  font-weight: 600;
  font-size: 16px;
  color: #333333;
}

.TaskTableHeadRow {
  white-space: nowrap;
  background-color: #f3f3fd !important;

}
.TaskTableDataRow {
  background-color: #ffffff;
  font-size: 16px;
  color: #444444;
  text-align: left !important;
}
.TableDataRow {
  background-color: #ffffff;
  font-size: 16px;
  color: #444444;
  text-align: justify;
}
.TableHeadRow th,
.TableDataRow td {
  border: 1px solid #dee2e6;
  padding: 10px 20px;
}
.TableDataRowSorted {
  background-color: #ffe6e1 !important;
}
.TableHeadRowSortable {
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.TableDataRow {
  background-color: #ffffff;
  font-size: 16px;
  color: #444444;
  text-align: justify;
}
.TableDataRow td:nth-child(1) {
  min-width: 100px;
}
.HighlightedText {
  font-size: 16px;
  color: #0daa47;
  font-weight: bold;
  min-width: 200px;
}
.TextStatusGood {
  font-family: "Roboto-Medium";
  color: #0daa47;
  font-weight: 500;
  min-width: 200px;
}
.TextStatusBad {
  font-family: "Roboto-Medium";
  color: #d51919;
  font-weight: 500;
  min-width: 200px;
}
.TextStatusNeutral {
  font-family: "Roboto-Medium";
  color: #ed9521; /*rgb(237,149,33) */
  font-weight: 500;
  min-width: 200px;
}

.TableTextBold {
  font-weight: 600;
}

/* Blue Theme */
.TableHeadClassBlue {
  background-color: var(--color-primary-dull) !important;
}
.TableHeadHoverClassBlue:hover, .TableHeadSortedClassBlue {
  background-color: var(--color-primary-dull-2) !important;
}
.PaginationButton {
  border: 1px solid var(--border-color);
  color: var(--color-darker-grey);
  font-size: 16px;
}
.PaginationButton:hover {
  border: 1px solid var(--border-color);
  background-color: var(--color-primary-dull);
  color: var(--color-darker-blue);
}
.PaginationActiveButton,.PaginationButton:active {
  border: 1px solid var(--border-color) !important;
  background-color: var(--color-primary) !important;
  color: var(--color-white) !important;
}
.PaginationButton:disabled {
  border: 1px solid var(--border-color);
  background-color: var(--border-color);
  color: var(--color-light-grey);
}

/* For Toxicity Detector */
.FlexContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.TableLeft {
  width: 65%;
}
.Container {
  width: 32%;
}
.AddTable {
  display: flex;
  flex-direction: column;
  border: 1px solid #d2d2d2;
}
.AddTableHead {
  height: 45px;
  border: 1px solid #dee2e6;
}
.AddTableData {
  font-size: 42px;
  font-weight: 600;
  min-height: 270px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border-bottom: 1px solid #dee2e6;
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
}
.Icon {
  width: 18px;
  cursor: pointer;
}
.DisableIcon{
  opacity: 0.6;
  width: 18px;
  pointer-events: none;
}
.PreviewIcon {
  width: 24px;
  cursor: pointer;
}
.PreviewDisableIcon {
  opacity: 0.6;
  width: 24px;
  pointer-events: none;
}
.LoaderContainer {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d5d5d5;
  margin-top: 18px;
}
.LoadingImageMedium {
  width: 52px;
  height: 52px;
}
.FormattedOutputpopup {
  border-radius: 0px !important;
}
.LinkText{
  cursor: pointer;
    color: rgb(33, 89, 201);
    width: fit-content;
    /* text-align: end; */
}

.RefreshLink{
  cursor: pointer;
    /* color: rgb(33, 89, 201); */
    width: fit-content;
   margin-left: auto;
   /* text-decoration: underline; */
   color: var(--color-primary);

}

.RefreshIcon{
  width: 12px;
  cursor: pointer;
  margin-right: 5px;
}

.TableCell{
    min-width: 150px !important;
    max-width: 200px;
    word-break: break-word;
}

.TableCellMediumText{
  min-width: 180px !important;
  max-width: 380px;
  word-break: break-word;
}

.TableCellLongText{
  /* background-color: #d51919!important; */
  min-width: 220px !important;
  max-width: 380px;
  word-break: break-word;
}

.TableCellDate{
  min-width: 150px !important;
  max-width: 250px;
  word-break: break-word;
}

.SubTableHead {
  white-space: nowrap;
  background-color: #f3f3fd !important;
}