.ConfiguratorCard {
  border-radius: 15px !important;
  border: 0.5px solid #d2d2d2 !important;
  width: 100% !important;
  position: relative;
  background-color: var(--color-lighter-back);
  overflow: hidden;
  transition: 0.2s;
}





.ConfiguratorCardImgContainer {
  height: 100% !important;
  width: 100%;
  position: relative;
}

.ConfiguratorCardImgContainer img{
  width: 90%;
  height: 80px;
  object-fit: contain !important;
}


.ConfiguratorCardUseIcon {

  color: var(--color-primary);
  background-color: var(--color-primary-dull);
  border-radius: 4px;
  width: 40px;
  height: 43px;
  padding: 10px 5px;
  cursor: pointer;
}

.activeKeyClass {
  position: absolute; 
  top: 10px; 
  right: 10px; 
  z-index: 2;
  border-radius: 4px;
  width: auto;
  height: auto;
  font-size: 12px;
  padding: 0px 8px;
  font-family: 'GoogleSans';
}
.activeKeyClass.inUse{
  color: var(--success-text);
  background-color: var(--success-text-dull);
  border-color: var(--success-text);
}
.activeKeyClass.notInUse{
  color: var(--error-text);
  border-color: var(--error-text);
  background-color: var(--error-text-dull);
}

.ConfiguratorDeleteClass {
  color: var(--color-white) !important;
  background-color: var(--color-error);
  border-radius: 4px;
  width: 100%;
  height: 100%;
  padding: 10px 5px;
  cursor: pointer;
}
.ConfiguratorRemoveBR {
  border-radius: 0px;
  font-size: 14px;
}

.ButtonsContainer {
  display: flex;
  justify-content: space-between;
}

