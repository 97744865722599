.BackGround {
  background: rgba(255, 255, 255, 0.8) 0% 0% no-repeat padding-box;
  /* height: 1183px; */
  opacity: 1;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  position: absolute;
}
