.ContainerStyle {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
}
.LoaderImageStyle {
  width: 128px;
  height: 128px;
}
