.MenuContainer {
  background-color: var(--color-primary);
  padding: 10px;
  border-radius: 10px;
}

.MenuItem {
  font-size: 16px;
  font-family: "GoogleSans";
  font-weight: 400;
  color: var(--color-white);
  background-color: transparent;
  cursor: pointer;
  padding: 10px 8px;
  border-radius: 6px;
  margin: 5px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.MenuItem:hover {
  background-color: var(--color-primary-dull-2);
  color: var(--color-primary);
}

.MenuActiveItem {
  background-color: var(--color-primary-dull);
  color: var(--color-primary);
}

.HostingInfoParagraph,
.HostingInfoList {
  text-align: justify;
  line-height: 30px;
  margin-bottom: 10px;
  white-space: pre-line;
}

.HostingInfoCode {
  display: flex;
  align-items: flex-start;
  width: 90%;
  background-color: var(--color-light-back);
  padding: 20px;
  border-radius: 10px;
}

.HostingInfoCodeInfo,
.HostingInfoNote {
  font-size: 14px;
  line-height: 30px;
  color: var(--color-darker-grey);
}

.CodeBlock {
  flex-grow: 1;
}

.CopyCode {
  cursor: pointer;
  background-color: var(--color-primary-dull);
  width: 28px;
  height: 28px;
  padding: 5px;
  border-radius: 4px;
}

/* Blue Theme */
.TableHeadClassBlue {
  background-color: var(--color-primary-dull) !important;
}

.TableHeadHoverClassBlue:hover,
.TableHeadSortedClassBlue {
  background-color: var(--color-primary-dull-2) !important;
}

.PaginationButton {
  border: 1px solid var(--border-color);
  color: var(--color-darker-grey);
  font-size: 16px;
}

.PaginationButton:hover {
  border: 1px solid var(--border-color);
  background-color: var(--color-primary-dull);
  color: var(--color-darker-blue);
}

.PaginationActiveButton,
.PaginationButton:active {
  border: 1px solid var(--border-color) !important;
  background-color: var(--color-primary) !important;
  color: var(--color-white) !important;
}

.PaginationButton:disabled {
  border: 1px solid var(--border-color);
  background-color: var(--border-color);
  color: var(--color-light-grey);
}

.EnvPopupForm {
  background-color: var(--color-light-back);
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
  margin: 8px 0px;
}

.EnvLabel {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-size: 14px;
  font-family: "Roboto-Medium";
}

@media only screen and (min-width: 768px) {
  .EnvPopupForm {
    max-height: 65vh;
    overflow-y: auto;
  }
}