/* root variables */
:root {
  --color-disable-button-back: #b4b4b44d;
  --color-disable-button-text: #1010104d;
  --color-subscribed-card-border: #2761E6;
  --color-subscribed-card-background: #EFFEFF;
  --color-bright-blue: #2680EB;
  --color-active: #55D420;
  --color-expired: #F5CFC7;
  --color-connector: #989898;
  --color-addon: #3D3D3D;
  --color-price: #2D2D2D;
  --color-highlight: #800020;
  --color-white: #ffffff;
  --color-black: #000000;
  --color-dark-text: #444444;
  --color-medium-text: #333333;
  --color-light-text: #69696c;
  --color-dark-blue: #221e5b;
  --color-darker-blue: #202124;
  --color-grey: #5f6368;
  --color-dark-grey: #57585a;
  --color-grey44: #707070;
  --color-blue-grey: #7b7e82;
  --color-orange: #ff7c5f;
  --color-active-orange: #ff7962;
  --color-pink: #ff00b7;
  --color-darker-grey: #5f6368;
  --color-lighter-grey: #65696e;
  --color-light-grey: #878a8e;
  --color-hint-grey: #8D8D8D;
  --border-color: #d2d2d2;
  --footer-bg: #261037;
  --footer-title-text: #ffffff;
  --footer-text: #ffffff;
  --bg-color: #f8f8f8;
  --nav-text-color: #303030;
  --load-text-color: #ababab;
  --menu-hover-color: #4b5ff80d;
  --home-page-text-color: #ffffff;
  --error-text: #ff3860;
  --success-text: #23d160;
  --success-text-dull:#dffae8;
  --error-text-dull:#ffd4dd;
  --warning-text: #FFD538;
  --model-group-bg-color: #FFF3FB;
  --blog-content-text: #7d7d7d;

  /* blue themes */
  --color-primary-dark: #201D60;
  --color-primary: #5A66EA;
  --color-primary-dull: #ebedff;
  --color-primary-dull-2: #cbd0ff;
  --color-secondary: #53B2ED;
  --color-link: #6217E8;
  --color-primary-dark-back: #201D60;
  --color-primary-light-back: #FFFFFF;
  --color-lighter-back: #fcfcfc;
  --color-light-back: #F4F4F4;
  --color-medium-back: #E4E4E4;
  --color-border: #D2D2D2;
  --color-error: #ff3860;
  --color-success: #23d160;
  --color-warning: #FFD538;

  --nav-text-size: 16px;
  --menu-text-size: 16px;
  --subtitle: 24px;
  --placeholder-text: 14px;
  --short-label: 16px;
  --card-title-label: 16px;
  --back-link: 16px;
  --tab-title: 18px;
  --button-label: 16px;
  --table-content: 14px;
  --spec-table-menu: 18px;
  --figure-caption: 12px;
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/Roboto-Regular.ttf");
  /* font-display: swap; */
  /* src: local('Segoe UI'), url('./assets/fonts/Segoe\ UI.woff') format('woff'); */
}

@font-face {
  font-family: "Roboto-Medium";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/Roboto-Medium.ttf");
  /* font-display: swap; */
  /* src: local('Segoe UI'), url('./assets/fonts/Segoe\ UI.woff') format('woff'); */
}

@font-face {
  font-family: "Roboto-Light";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/Roboto-Light.ttf");
  /* font-display: swap; */
  /* src: local('Segoe UI'), url('./assets/fonts/Segoe\ UI.woff') format('woff'); */
}

@font-face {
  font-family: "GoogleSans";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/GoogleSans-Regular.ttf");
  /* font-display: swap; */
  /* src: local('Segoe UI'), url('./assets/fonts/Segoe\ UI.woff') format('woff'); */
}

/* @font-face {
  font-family: 'Roboto Bold';
  src:url('./assets/fonts/Roboto-Bold.ttf')
  src: local('Segoe UI'), url('./assets/fonts/Segoe\ UI.woff') format('woff');
} */

body {
  font-family: "Roboto";
  overflow-x: hidden !important;
  /* text-align: justify; */
}

.BodyContent {
  min-height: calc(100vh - 64px - 184px);
  width: 100%;
}

.ScrollContainer {
  /* overflow-y: auto; */
}

.ScrollContainer::-webkit-scrollbar {
  /* width: 15px !important; */
  display: none;
}

.ScrollContainer::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #dddedf;
}

.ScrollToTop {
  height: 36px;
  width: 36px;
  border: none;
  border-radius: 50px;
  background-color: var(--color-primary);
  color: var(--color-white);
  position: fixed;
  bottom: 5px;
  right: 1%;
}

/* Line classes */
.VerticalLine {
  /* background-color: var(--color-white);
  box-shadow: inset 1px 1px 2px var(--border-color); */
  width: 1px;
  height: 100%;
  background-image: linear-gradient(to bottom, var(--color-white), var(--border-color), var(--color-white));
}

.VerticalLineNormal {
  background-color: var(--border-color);
  width: 1px;
  height: 100%;
}

.HorizontalLine {
  /* background-color: var(--border-color); */
  width: 100%;
  height: 1px;
  background-image: linear-gradient(to left, var(--color-white), var(--border-color), var(--color-white));
}

.HorizontalLineNormal {
  background-color: var(--border-color);
  width: 100%;
  height: 1px;
}

/* Icon Classes */
.IconMargins {
  margin: 0px 3px 3px 0px;
}

/* FOnt Color Classes */
.FontPrimary{
  color: var(--color-primary);
  
}

/* Cursor Classes */
.PointerCursor {
  cursor: pointer !important;
}

.DefaultCursor {
  cursor: default;
}

.TextCursor {
  cursor: text;
}

.DisblePointer {
  pointer-events: none !important;
  opacity:0.5
}

/* Badge Classes */
.BlueBadge {
  background: #E8F1FF 0% 0% no-repeat padding-box;
  border: 1px solid #2748C1;
}

.GreenBadge {
  background: #F1FFF7 0% 0% no-repeat padding-box;
  border: 1px solid #5DC25E;
}

.YellowBadge {
  background: #FCFFCF 0% 0% no-repeat padding-box;
  border: 1px solid #F2BA14;
}

.WhiteBadge {
  
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #379FEF;
}

/* Button Classes */
.PrimaryButton {
  background: transparent linear-gradient(90deg, #ff00b7 0%, #ff7f5e 100%) 0% 0% no-repeat padding-box !important;
  color: #ffffff !important;
}

.PrimaryButton:disabled {
  background: none !important;
  background-color: var(--color-disable-button-back) !important;
  color: var(--color-disable-button-text) !important;
  border: 1px solid var(--border-color) !important;
}

/* Card classes */
.BorderCard {
  border: 1px solid var(--border-color) !important;
  border-radius: 0px 0px 8px 8px !important;
  border-top: 5px solid var(--color-primary-dark) !important;
}

.BorderCard:hover {
  border: 1px solid var(--color-primary) !important;
  border-top: 5px solid var(--color-primary) !important;
}

/* Form UI */
.SelectLabel {
  font: normal normal normal 14px "Roboto";
  letter-spacing: 0px;
  color: var(--color-darker-blue);
  opacity: 0.8;
  text-align: left !important;
}

.FormCard {
  border-radius: 12px;
  box-shadow: 0px 3px 7px #d2d2d2;
  background-color: #ffffff;
}

.FormLabel {
  font-weight: 400;
  color: var(--color-dark-grey);
  margin-bottom: 5px;
  font-size: 14px;
}

.FormTextArea {
  resize: none;
  overflow-y: auto;
}

.FormInput {
  font-size: 14px;
  color: var(--color-darker-blue);
  opacity: 0.85;
  box-shadow: none !important;
  border: 1px solid #d2d2d2 !important;
  border-radius: 4px;
  outline: none;
}

.FormInputCheckBox, .FormInputCheckBoxBlue {
  cursor: pointer;
}

/* .FormInputCheckBox:disabled {
  cursor: default;
}
.FormInputCheckBox:read-only {
  cursor: default;
  opacity: 1 !important;
  pointer-events : none;
} */
.FormInputCheckBox:checked {
  background-color: #ff7f5e !important;
  border: 1px solid #ff7f5e;
}

.FormInputCheckBoxBlue:checked {
  background-color: var(--color-primary) !important;
  border: 1px solid var(--color-primary);
}

.FormInputCheckBox:focus,
.FormInputCheckBox:active,
.FormInputCheckBoxBlue:focus,
.FormInputCheckBoxBlue:active {
  outline: none !important;
  box-shadow: none;
}

.FormRequiredInput::after {
  content: " *";
  color: var(--error-text);
}

.ErrorFormInput {
  border: 1px solid var(--error-text) !important;
  border-radius: 4px;
}

.ErrorText {
  height: 10px;
  font-size: 12px;
  padding: 2px;
  color: var(--error-text);
}

.SupportText {
  font-size: 12px;
  padding: 2px;
  color: var(--color-light-grey)
}

/* Loader Styles */
.LoaderExtraSmallSize {
  width: 28px;
  height: 28px;
  margin: 4px 8px;
}

.LoaderSmallSize {
  width: 36px;
  height: 36px;
  margin: 4px 8px;
}

.LoaderMediumSize {
  width: 56px;
  height: 56px;
  margin: 4px 8px;
}

.FullPageLoader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.8) 0% 0% no-repeat padding-box;
  z-index: 2000;
}

.AbsoluteComponentLoader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  z-index: 2;
  background: rgba(255, 255, 255, 0.8) 0% 0% no-repeat padding-box;
}

/* Division Styles */
.Error {
  /* border: 1px solid #FBCDCD !important; */
  border: 1px solid #E7464633 !important;
  font-size: 14px;
  border-radius: 3px;
  background-color: #FFDAE0;
  /* background-color: #FFEFEF !important; */
  /* color: #FB8181 !important; */
  color: #E62A2A;
  padding: 10px 12px !important;
  margin: 8px 0px;
}

.Info {
  border: 1px solid #CDE4FB !important;
  border-radius: 8px;
  background-color: #EFFEFF !important;
  color: var(--color-light-text) !important;
  padding: 10px 15px !important;
  margin: 10px 0px;
}

.Warning {
  border: 1px solid #FCD6B5 !important;
  border-radius: 8px;
  background-color: #FFFEEF !important;
  color: var(--color-light-text) !important;
  padding: 10px 15px !important;
  margin: 10px 0px;
}

/* TableCell */
.TableCell {
  min-width: 150px !important;
  max-width: 20px;
  word-break: break-word;
}

.FixedWidth20Cent {
  width: 20%;
}

.FixedWidth50 {
  width: 50px
}

.FixedWidth42 {
  width: 42px
}
.FixedWidth100 {
  width: 115px;
}

.FixedWidth150 {
  width: 150px;
}

.FixedWidth200 {
  width: 200px;
}

.FixedWidth300 {
  width: 300px;
}

.NoWrap{
  white-space: nowrap;
}

/* font */
.AnchorElement {
  text-decoration: none;
}
.FontLink a {
  color: var(--color-orange);
  cursor: pointer;
  text-decoration: none;
}

.FontBold {
  font-weight: 600;
}

.Font16White {
  font-size: 16px;
  color: var(--color-white) !important;
  opacity: 0.8;
}

.Font46White {
  font-size: 46px;
  font-weight: 600;
  color: var(--color-white) !important;
  
}

.Font12White {
  font-size: 12px;
  color: var(--color-white) !important;
  
}

.Font15White {
  font-size: 15px;
  color: var(--color-white) !important;
  
}

.Font18BoldDark {
  font-size: 18px;
  font-weight: 600;
  color: var(--color-dark-text);
}

.Font16Dark {
  font-size: 16px;
  color: var(--color-dark-text);
}

.Font16Light {
  font-size: 16px;
  color: var(--color-light-text);
}

.Font14Light {
  font-size: 14px;
  color: var(--color-light-text);
}

.Font24 {
  font-size: 24px;
  color: var(--color-dark-blue);
}

.Font24Bold {
  font-weight: 600;
  font-size: 24px;
  color: var(--color-dark-blue);
}

.Font16BrightBlue {
  font-size: 16px;
  color: var(--color-bright-blue);
}

.Font16Blue {
  font-size: 16px;
  color: var(--color-dark-blue);
}

.Font42Bold {
  font-size: 42px;
  font-weight: 600;
  color: var(--color-dark-blue);
}

.Font42Black {
  font-size: 42px;
  color: var(--color-darker-blue);
}

.Font18Blue {
  font-size: 18px;
  color: var(--color-dark-blue);
}

.Font18MediumBlue {
  font-family: "Roboto-Medium";
  font-size: 18px;
  font-weight: 500;
  color: var(--color-dark-blue);
}

.Font20Blue {
  font-size: 20px;
  color: var(--color-dark-blue);
}

.Font24MediumBlue {
  font-family: "Roboto-Medium";
  font-size: 24px;
  font-weight: 500;
  color: var(--color-dark-blue);
}

.Font16Bold {
  font-size: 16px;
  font-weight: bold;
  color: var(--color-dark-blue);
}

.Font16Blue {
  font-size: 16px;
  color: var(--color-dark-blue);
}

.Font16 {
  font-size: 16px !important;
  color: var(--color-darker-blue) !important;
}

.Font20 {
  font-size: 20px !important;
  color: var(--color-darker-blue) !important;
}

.Font16Text {
  font-size: 16px;
  color: var(--nav-text-color);
}

.Font18Text {
  font-size: 18px;
  color: var(--nav-text-color);
}

.Font20Text {
  font-size: 20px;
  color: var(--nav-text-color);
}

.Font32BoldBlue {
  font-size: 32px;
  font-weight: bold;
  color: var(--color-dark-blue);
}

.Font32MediumBlue {
  font-family: "Roboto-Medium";
  font-size: 32px;
  font-weight: 500;
  color: var(--color-dark-blue);
}

.Font36BoldBlue {
  font-size: 36px;
  font-weight: bold;
  color: var(--color-dark-blue);
}

.Font36MediumBlue {
  font-family: "Roboto-Medium";
  font-size: 36px;
  font-weight: 500;
  color: var(--color-dark-blue);
}

.Font36BoldDarkerBlue {
  font-size: 36px;
  font-weight: 600;
  color: var(--color-darker-blue);
}

.Font30MediumDarkerBlue {
  font-size: 30px;
  font-family: 'Roboto-Medium';
  font-weight: 500;
  color: var(--color-darker-blue);
}

.Font28MediumDarkerBlue {
  font-size: 28px;
  font-weight: 600;
  color: var(--color-darker-blue);
}

.Font28DarkerBlue {
  font-size: 28px;
  color: var(--color-darker-blue);
}

.Font24BoldDarkerBlue {
  font-size: 24px;
  font-weight: 600;
  color: var(--color-darker-blue);
}

.Font18MediumDarkerBlue {
  font-family: "Roboto-Medium";
  font-size: 18px !important;
  font-weight: 500;
  color: var(--color-darker-blue);
}

.Font24DarkerBlue {
  font-size: 24px;
  color: var(--color-darker-blue);
}

.Font18BoldDarkerBlue {
  font-size: 18px;
  font-weight: bold;
  color: var(--color-darker-blue);
}

.Font16MediumDarkerBlur {
  font-family: "Roboto-Medium";
  font-size: 16px !important;
  font-weight: 500;
  color: var(--color-darker-blue);
}

.Font14DarkerBlue {
  font-size: 14px;
  color: var(--color-darker-blue);
}

.Font18BoldOrange {
  font-size: 18px;
  font-weight: 600;
  color: var(--color-orange) !important;
}

.Font18BoldPrimary {
  font-size: 18px;
  font-weight: 600;
  color: var(--color-primary) !important;
}

.Font16Orange {
  font-size: 16px;
  color: var(--color-orange) !important;
}

.Font16MediumOrange {
  font-family: "Roboto-Medium";
  font-size: 16px;
  font-weight: 500;
  color: var(--color-orange) !important;
}

.Font18MediumOrange {
  font-family: "Roboto-Medium";
  font-size: 18px;
  font-weight: 500;
  color: var(--color-orange) !important;
}
.Font18MediumPrimaryBlue {
  font-family: "Roboto-Medium";
  font-size: 18px;
  font-weight: 500;
  color: var(--color-primary) !important;
}

.Font16PrimaryBlue {
  font-size: 16px;
  color: var(--color-primary) !important;
}

.Font14Orange {
  font-size: 14px;
  color: var(--color-orange);
}

.Font14Primary {
  font-size: 14px;
  color: var(--color-primary);
}

.Font14MediumPrimary {
  font-size: 14px !important;
  font-family: "Roboto-Medium" !important;
  color: var(--color-primary) !important;
}

.Font14MediumPrimaryDark {
  font-size: 14px !important;
  font-family: "Roboto-Medium" !important;
  color: var(--color-primary-dark) !important;
}


.Font14ActiveOrange {
  font-size: 14px;
  color: var(--color-active-orange);
}

.Font36BoldGrey44 {
  font-size: 36px;
  font-weight: bold;
  color: var(--color-grey44);
}

.Font18BoldGrey44 {
  font-size: 18px;
  font-weight: 600;
  color: var(--color-grey44);
}

.Font18Grey44 {
  font-size: 18px;
  color: var(--color-grey44);
}

.Font16Grey44 {
  font-size: 16px;
  color: var(--color-grey44);
}

.Font16black {
  font-size: 16px;
  color: var(--color-primary-dark);
}

.Font16LighterGrey {
  font-size: 16px;
  color: var(--color-lighter-grey);
}

.Font18LighterGrey {
  font-size: 18px;
  color: var(--color-lighter-grey);
}

.Font20LighterGrey {
  font-size: 20px;
  color: var(--color-lighter-grey);
}

.Font16LightGrey {
  font-size: 16px;
  color: var(--color-light-grey);
}

.Font42BoldDarkerGrey {
  font-size: 42px;
  font-weight: 600;
  color: var(--color-darker-grey);
}

.Font36BoldDarkerGrey {
  font-size: 36px;
  font-weight: 600;
  color: var(--color-darker-grey);
}

.Font32BoldDarkerGrey {
  font-size: 32px;
  font-weight: 600;
  color: var(--color-darker-grey);
}

.Font28BoldDarkerGrey {
  font-size: 28px;
  font-weight: 600;
  color: var(--color-darker-grey);
}

.Font24BoldDarkerGrey {
  font-size: 24px;
  font-weight: 600;
  color: var(--color-darker-grey);
}

.Font24MediumDarkerGrey {
  font-family: "Roboto-Medium";
  font-size: 24px;
  font-weight: 500;
  color: var(--color-darker-grey);
}

.Font18DarkerGrey {
  font-size: 18px;
  color: var(--color-darker-grey);
}

.Font15DarkerGrey {
  font-size: 16px;
  color: var(--color-darker-grey);
}

.Font14DarkerGrey {
  font-size: 14px;
  color: var(--color-darker-grey);
}

.Font14MediumDarkerGrey {
  font-family: "Roboto-Medium";
  font-size: 14px;
  color: var(--color-darker-grey);
}

.Font18BoldAddonText {
  color: var(--color-addon);
  font-size: 18px;
  font-weight: 600;
}

.Font24BoldPriceText {
  color: var(--color-price);
  font-size: 24px;
  font-weight: 600;
}

.Font26BoldConnectersText {
  color: var(--color-connector);
  font-size: 26px;
  font-weight: 600;
}

.Font12BlueGrey {
  font-size: 12px;
  color: var(--color-blue-grey);
}

.Font14BlueGrey {
  font-size: 14px;
  color: var(--color-blue-grey);
}

.Font18BoldGrey {
  font-size: 18px;
  font-weight: bold;
  color: var(--color-grey);
}

.Font18MediumGrey {
  font-family: "Roboto-Medium";
  font-size: 18px;
  font-weight: 500;
  color: var(--color-grey);
}

.Font16MediumGrey {
  font-family: "Roboto-Medium";
  font-size: 16px;
  font-weight: 500;
  color: var(--color-grey);
}

.Font18Grey {
  font-size: 18px;
  color: var(--color-grey);
}

.Font16Grey {
  font-size: 16px;
  color: var(--color-grey);
}

.Font15BoldGrey {
  font-size: 15px;
  font-weight: 600;
  color: var(--color-grey);
}

.Font15Grey {
  font-size: 15px;
  color: var(--color-grey);
}

.Font14MediumGrey {
  font-family: "Roboto-Medium";
  font-size: 14px;
  color: var(--color-grey);
}

.Font14Grey {
  font-size: 14px;
  color: var(--color-grey);
}

.Font12Grey {
  font-size: 12px;
  color: var(--color-grey);
}

.Font12Warning {
  font-size: 12px;
  color: var(--warning-text);
}

.Font18BoldDarkGrey {
  font-size: 18px;
  font-weight: bold;
  color: var(--color-dark-grey);
}

.Font32BoldDarkGrey {
  font-size: 30px;
  font-weight: bold;
  color: var(--color-dark-grey);
}


.Font16DarkGrey {
  font-size: 16px;
  color: var(--color-dark-grey);
}

.Font16LoadText {
  font-size: 16px;
  color: var(--load-text-color);
}

.LabelInfo {
  font-size: 12px;
  color: var(--color-white);
  background-color: var(--color-black);
  padding: 5px 8px;
  border-radius: 5px;
  opacity: 0.8;
  white-space: pre-wrap;
  line-height: 24px;
  width: 50%;
}

.PopupLabelInfo {
  font-size: 12px;
  color: var(--color-white);
  background-color: var(--color-black);
  padding: 5px 8px;
  border-radius: 5px;
  opacity: 0.8;
  white-space: pre-wrap;
  line-height: 24px;
  width: 50%;
  z-index: 5000 !important
}

/* font family styles */
.FontGoogleSans {
  font-family: "GoogleSans" !important;
}

.FontRobotoLight {
  font-family: "Roboto-Light" !important;
}

.FontRobotoMedium {
  font-family: "Roboto-Medium" !important;
  font-weight: 500 !important;
}

.FontRoboto {
  font-family: "Roboto" !important;
}

.PopupBack {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2000 !important;
  position: fixed !important;
  background: rgba(0, 0, 0, 0.4) 0% 0% no-repeat padding-box !important;
  /* border: 1px solid #707070 !important; */
  opacity: 1;
}

.OrangeContainer {
  background: #FFF9F9 0% 0% no-repeat padding-box !important;
  border: 1px solid #FCDFDF !important;
  border-radius: 8px;
  opacity: 1;
}