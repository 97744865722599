.CardContainer {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d5d5d5;
  border-radius: 15px;
  opacity: 1;
  min-height: 50px;
}
.GradientCardContainer {
  display: flex;
  flex-direction: column;
  background: transparent linear-gradient(180deg, #ffffff 0%, #f8f8f8 100%) 0%
    0% no-repeat padding-box;
  border: 1px solid #d2d2d2;
  border-radius: 12px;
  opacity: 1;
  height: 100%;
  text-align: left;
}
.CardInfo {
  display: flex;
  flex-direction: column;
}
.CardHead {
  font-family: "GoogleSans";
  font-weight: 600;
  font-size: 24px;
  letter-spacing: 0px;
  color: #444444;
  opacity: 1;
  margin-bottom: 10px;
}
.CardContent {
  font-size: 16px;
  letter-spacing: 0px;
  color: #5f6368;
  opacity: 1;
}
.CardImage {
  width: 64px;
  height: 64px;
  margin-bottom: 15px;
  /* align-self: flex-end; */
}
