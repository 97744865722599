.Container {
  padding: 40px 0px 60px 0px;
}
.HeadingText {
  font-size: 36px !important;
  text-align: center;
}
.SubText {
  font-size: 16px !important;
  text-align: center;
  margin-top: 10px;
}
.SubText a{ 
  text-decoration: none;
  color : var(--color-primary);
}
.Image {
  width: 615px;
  height: auto;
}
.OwnImage{
  width: 200px;
  height: auto;
  margin: 30px 0px;
}
@media only screen and (max-width: 768px) {
  .Container {
    padding: 20px 15px;
  }
  .HeadingText {
    font-size: 20px !important;
  }
  .SubText {
    font-size: 14px !important;
  }
  .Image {
    width: 100%;
    height: auto;
  }
  .OwnImage{
    width: 150px;
    height: auto;
    margin: 30px 0px;
  }
}
@media only screen and (min-width: 768px) {
  .Container {
    padding: 40px 0px;
  }
  .HeadingText {
    font-size: 32px !important;
  }
  .SubText {
    font-size: 15px !important;
  }
  .Image {
    width: 480px;
    height: auto;
  }
  .OwnImage{
    width: 200px;
    height: auto;
    margin: 30px 0px;
  }
}
@media only screen and (min-width: 992px) {
  .Container {
    padding: 40px 0px 60px 0px;
  }
  .HeadingText {
    font-size: 36px !important;
  }
  .SubText {
    font-size: 16px !important;
  }
  .Image {
    width: 615px;
    height: auto;
  }
}
