.Container {
  border-radius: 15px;
  padding: 8px 0px;
}
.TableHeadRowSortable {
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.TableHoverHead:hover,.TableDataRowSorted {
  background-color: var(--color-primary-dull-2) !important;
}

.TableHeadRow th {
  background-color: var(--color-primary-dull);
  font-weight: 600;
  font-size: 15px;
  color: var(--color-primary-dark) !important;
}

.THead:first-child {
  border-radius: 10px !important;
}

.TBody {
  max-height: 120px !important;
  overflow-y: auto;
  font-size: 15px;
}

.TBody .TableDataRow:nth-child(even) td{
  background-color: #fafafa !important;
}

.TableHead {
  white-space: nowrap !important;
}

.TableDataRow td{
  background-color: var(--color-white);
}

.TFoot:last-child{
  border-radius: 10px !important;
}

.Icon {
  width: 18px;
  cursor: pointer;
}

.DisableIcon {
  opacity: 0.6;
  width: 18px;
  pointer-events: none;
}

.TaskTableHeadRow {
  white-space: nowrap;
  background-color: #f3f3fd !important;
}

.TableCell {
  min-width: 150px !important;
  max-width: 20px;
  word-break: break-word;
}

.TableCellLongText {
  /* background-color: #d51919!important; */
  min-width: 320px !important;
  max-width: 380px;
  word-break: break-word;


}

.TableCellDate {
  min-width: 230px !important;
  max-width: 250px;
  word-break: break-word;

}

.NoteText {
  color: var(--color-light-grey);
  font-size: 14px;
  padding: 10px;
}
.NoteText label {
  font-family: 'Roboto-Medium';
}

/* Pagination Styles */
.PageButtonSize {
  font-size: 16px;
}
.PageButton {
  border: 1px solid var(--border-color);
  color: var(--color-darker-grey);
}
.PageButton:hover {
  border: 1px solid var(--border-color);
  background-color: var(--color-primary);
  color: var(--color-white);
}
.ActivePage,.PageButton:active {
  border: 1px solid var(--border-color) !important;
  background-color: var(--color-primary) !important;
  color: var(--color-white) !important;
}
.PageButton:disabled {
  border: 1px solid var(--border-color);
  background-color: var(--border-color);
  color: var(--color-light-grey);
}
.PageText {
  font-family: 'GoogleSans';
  font-size: 16px;
  color: var(--color-light-grey);
}

.PageText input {
  text-align: center;
  border: 1px solid var(--border-color);
  color: var(--color-light-grey);
  border-radius: 4px;
  width: 30px;
}
.PageText input:focus {
  border: 1px solid var(--border-color);
}
.PageText input:focus-visible {
  outline: none;
}

/* ScrollBar Style */
.ResponsiveTableContainer::-webkit-scrollbar {
  height: 5px !important;
}
.ResponsiveTableContainer::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: var(--border-color);
}
.ResponsiveTableContainer::-webkit-scrollbar-thumb {
  background: var(--color-light-grey); 
  border-radius: 5px;
}
.ResponsiveTableContainer::-webkit-scrollbar-thumb:hover {
  background: var(--color-blue-grey); 
}

/* Export Button Classes */
.ExportIcon {
  border: 1px solid var(--border-color);
  border-radius: 6px;
  cursor: pointer;
}
.ExportIcon img{
  width: 24px;
  height: auto;
  margin: 8px 6px;
}

@media only screen and (max-width: 576px) {
  .PageButtonSize {
    font-size: 12px;
    padding: 5px 10px;
  }
  .ExportIcon img{
    width: 16px;
    height: auto;
    margin: 4px;
  }
}