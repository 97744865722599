.Button {
  font-family: "Roboto-Medium";
  color: #ff7c5f;
  font-size: 14px !important;
  border-radius: 6px;
  background: #ffffff 0% 0% no-repeat padding-box;
  padding: 10px 25px 10px 25px;
  border: 1px solid var(--border-color);
  vertical-align: middle;
  white-space: nowrap;
}

.Button:hover {
  background: #ff7c5f !important;
  color: #ffffff !important;
  border: 1px solid #ff7c5f;
}

.Button:disabled {
  background: none !important;
  background-color: var(--color-disable-button-back) !important;
  color: var(--color-disable-button-text) !important;
  border: 1px solid var(--border-color) !important;
}

.ButtonIcon {
  width: 20px;
  height: 20px;
}

/* New Buton Classes */
.ButtonNew {
  position: relative;
  font-family: "Roboto-Medium";
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: 0.1s;
  text-decoration: none;
}
.ButtonNew:active {
  transform: scale(0.96);
}
.ButtonNew:disabled{
  background-color: var(--color-medium-back) !important;
  color: #bfbfbf !important;
  pointer-events: none;
}
.ButtonNew::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  background-color: #000000;
  opacity: 0;
  overflow: hidden;
  transition: 0.3s;
}

/* Action Types */
.Regular {
  background-color: #ffffff;
  color: var(--color-primary-dark) !important;
}

.Primary {
  background-color: var(--color-primary) !important;
  color: #ffffff !important;
}

.Secondary, .Tertiary {
  background-color: #ffffff !important;
  color: var(--color-primary) !important;
}

.Link {
  background-color: var(--color-link) !important;
  color: #ffffff !important;
}

.Error {
  background-color: #ffffff !important;
  color: var(--color-error) !important;
}


/* Radius Types */
.Pill {
  border-radius: 50px !important;
}
.Pill:hover.Link::before,
.Pill:hover.Regular::before {
  opacity: 0.1;
  border-radius: 50px;
}

.RoundedRectagle {
  border-radius: 6px ;
}
.RoundedRectagle:hover.ButtonNew::before {
  opacity: 0.1;
  border-radius: 6px;
}

/* Border Types */
.Bordered {
  border: 1px solid var(--color-primary);
}
.Bordered.Primary {
  border: 1px solid #ffffff;
}
.Bordered:disabled{
  pointer-events: none;
  border: 1px solid var(--color-medium-back) !important;
}
.NoBorder {
  border : 1px solid transparent !important;
}

/* Dropdown Classes */
.GroupButton{
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.GroupDropButton{
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
.DropDownItem {
  cursor: pointer;
  display: flex;
  flex-direction: column;
}

.DropDownTitleItem {
  cursor: pointer;
  font-size: 14px;
  font-family: "Roboto-Medium";
  color: var(--color-darker-grey);
  margin-bottom: 6px;
}

.DropDownDescriptionItem {
  cursor: pointer;
  font-size: 12px;
  color: var(--color-light-grey);
}

@media only screen and (max-width: 600px) {
  .ButtonNew {
    font-size: 12px;
  }
}

@media only screen and (min-width: 600px) {
  .ButtonNew {
    font-size: 12px;
  }
}

@media only screen and (min-width: 768px) {
  .ButtonNew {
    font-size: 14px;
  }
} 

@media only screen and (min-width: 992px) {
  .ButtonNew {
    font-size: 14px;
  }
} 

@media only screen and (min-width: 1200px) {
  .ButtonNew {
    font-size: 14px;
  }
}