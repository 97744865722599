.Container {
  display: flex;
  align-items: center;
}
.Progress {
  background-color: #e4e4e4;
  border-radius: 0;
  width: 70%;
  min-width: 100px;
  max-width: 300px;
}
.Progressbar {
  background-color: #4172e5;
}
.ProgressBarPositive {
  background-color: #23d160;
}
.ProgressBarNegative {
  background-color: #ff3860;
}
.ProgressBarNeutral {
  background-color: #ffdd57;
}
.ValueText {
  margin-left: 10px;
}
