.Container {
  position: fixed;
  /* right: 0; */
  right: 0;
  z-index: 5;
  bottom: 0;
  margin-bottom: 270px;
}
.Wrapper {
  position: relative;
  cursor: pointer;
  width: 36px;
  height: 36px;
  border-radius: 6px;
  display: flex;
  /* padding: 10px; */
  justify-content: center;
  transition: all 0.3s ease-out;
  background-image: linear-gradient(180deg, var(--color-secondary) 0%,var(--color-primary) 100%);
}
.FloatText {
  color: #ffffff;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  transform: scale(-1);
  padding: 10px 0px;
  font-family: "Roboto-Medium";
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 32px;
  transition: all 0.3s ease-out;
  /* opacity: 0; */
  display: none;
}
.Wrapper:hover {
  height: 130px;
}
.Icon {
  position: absolute;
  bottom: 0;
  margin-bottom: 5px;
  /* width: 36px; */
  transition: all 0.3s ease-out;
}
.Wrapper:hover .FloatText {
  display: block;
}
