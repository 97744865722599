.UserManagementContainer {
  min-height: 100vh;
}

.Banner {
  background: #eeeeee 0% 0% no-repeat padding-box;
  padding-top: 40px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
}

.ActiveLabel {
  color: var(--success-text)
}

.ExpiredLabel {
  color: var(--error-text);

}

.Title {
  font-family: "Roboto-Medium";
}

.ViewAllLink {
  text-decoration: none;
  font-size: var(---back-link);
  color: var(--color-darker-blue);
  align-self: center;
  opacity: 0.8;
  cursor: pointer;
}

.ViewAllLink svg {
  margin-top: -2px !important;
}

.ViewAllLink:hover {
  text-decoration: none;
  color: var(--color-darker-blue);
  opacity: 1;
}

.SupportRequestID {
  opacity: 1;
  /* text-transform: uppercase;
  color: var(--color-bright-blue);
  cursor: pointer; */
}

/* tab styles */
.Tabs {
  font-family: "GoogleSans";
  border-bottom: 0;
  background-color: #f5f5f5 !important;
  padding-left: 30px;
  font-size: 16px !important;
  font-weight: 600;
  flex-wrap: nowrap !important;
  overflow-x: auto;
  overflow-y: hidden;
}

.Tabs::-webkit-scrollbar {
  display: none;
}

.TabActive {
  border-bottom: 4px solid var(--color-primary) !important;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  background-color: #f5f5f5 !important;
  color: var(--color-darker-blue) !important;
  opacity: 1;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 16px;
  white-space: nowrap;
  border-radius: 6px;
}

.Tab {
  border: 0 !important;
  color: var(--color-grey);
  font-family: "GoogleSans";
  font-weight: 600;
  padding-top: 15px;
  padding-bottom: 15px;
  opacity: 0.7;
  font-size: 16px;
  white-space: nowrap;
}

.Pointer {
  cursor: pointer;
}

.Tab:hover {
  color: var(--color-darker-blue);
  opacity: 1;
}

/* button styles */
.ActionButtons {
  border: none;
  border-radius: 6px;
  color: var(--color-primary);
  font-size: 14px;
  background-color: var(--color-primary-dull);
  padding: 5px 8px;
  cursor: pointer;
  white-space: nowrap;
  font-family: "Roboto-Medium";
}

.Status{
  white-space: nowrap;
}

/* table styles */
.TableHeadRow {
  background-color: #fcf3f1;
  font-weight: 600;
  font-size: 16px;
  color: #333333;
}

.TableHeadRow th {
  white-space: nowrap;
}

.TableHeadRow th {
  white-space: nowrap !important;
}

table td {
  vertical-align: middle;
}

.SecretField {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* icon styles */
.IconContainer {
  background-color: var(--color-white);
  cursor: pointer;
  width: 28px;
  height: 28px;
  padding: 0px 5px;
  border-radius: 6px;
  border: none;
  box-shadow: 
  -1px -1px 3px var(--color-white),
  1px 1px 3px var(--border-color);
}
.IconContainer img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.Icon {
  width: 18px;
  height: 18px;
}
.ActionIcon {
  width: 28px;
  height: 28px;
  padding: 5px;
  /* border: 1px solid var(--border-color); */
  border-radius: 4px;
  background-color: rgba(255, 124, 95,0.08);
  box-shadow: 
  -1px -1px 3px var(--color-white),
  1px 1px 3px var(--border-color);
  cursor: pointer;
}

.ActionIconBlue {
  width: 28px;
  height: 28px;
  padding: 5px;
  /* border: 1px solid var(--border-color); */
  border-radius: 4px;
  background-color: var(--color-primary-dull);
  box-shadow: 
  -1px -1px 3px var(--color-white),
  1px 1px 3px var(--border-color);
  cursor: pointer;
}

.SubscriptionButton {
  font-size: 14px;
  font-family: "Roboto-Medium";
  color: var(--color-primary);
  background-color: var(--color-primary-dull);
  padding: 5px 8px;
  cursor: pointer;
  border-radius: 6px;
}

.ProductName {
  color: var(--color-darker-blue);
  font-weight: 600;
}

/* Text Colors */
.FontSuccess {
  color: var(--success-text);
}
.FontError {
  color: var(--error-text);
}

/* loader */
.Loader {
  position: absolute;
  top: 0;
  background: rgba(255, 255, 255, 0.8) 0% 0% no-repeat padding-box;
  z-index: 1;
  width: 100%;
  height: 100%;
}

/* Users Tab */
/* users tab serach styles */
.SearchInput {
  border-right: 0px solid transparent !important;
  border-radius: 6px 0px 0px 6px;
  padding: 10px 15px;
  font-size: 14px;
}

.SearchInput:focus {
  box-shadow: none;
  border: 1px solid #ced4da;
}

.SearchIcon {
  background-color: #fff !important;
  padding-right: 20px;
  border-radius: 0px 6px 6px 0px;
  border-left: 0px solid transparent !important;
}

/* users details view modal styles */
.ModalHead {
  color: var(--color-dark-blue);
  font-size: 36px;
  font-weight: 600;
}

.ModalIcon {
  cursor: pointer;
  width: 30px;
  height: 30px;
}

.ModalTable {
  background: #fbfbfb 0% 0% no-repeat padding-box;
  color: var(--color-grey);
}

.ModalTable tr {
  border: 1px solid #e1e1e1 !important;
}

.ModalTable td {
  padding: 10px 20px;
}

.ModalHeadData {
  font-family: "Roboto-Medium";
}

.ModalData {
  font-family: "Roboto-Medium";
  color: var(--color-darker-blue);
}

/* Create Account */
.AccountCreation {
  background-color: #f5f5f5;
  border-top: 1px solid #d2d2d2;
  border-bottom: 1px solid #d2d2d2;
}

.SuccessScreenPopup {
  padding: 20px 30px !important;
}

.BackButton {
  color: var(--color-primary);
  cursor: pointer;
}

/* Report */
.ReportDataHead {
  color: var(--color-grey44);
  font-size: 26px;
}

.ReportDataValue {
  font-weight: 600;
  color: #334753;
  font-size: 26px;
  margin-left: 10px;
}

.DetailedReportComponentLoader {
  width: 100%;
  height: 100%;
  margin-top: 50px;
}

.ReportComponentLoader {
  width: 100%;
  height: 150px;
}

.ChartCard {
  height: 100%;
}

.ChartTitle {
  color: var(--color-dark-blue);
  font-family: 'Roboto-Medium';
  font-size: 18px;
  border-bottom: 1px solid var(--border-color);
}

.ChartInfoImage {
  width: 28px;
  bottom: 0;
  right: 0;
}

/* Plan pane styles */
.CardHeightFill {
  height: 100%;
}

.AddCreditButton {
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
  border-radius: 5px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  width: 150px;
  justify-content: center;
  cursor: pointer;
}

.AddCreditButton:hover {
  background-color: var(--color-primary);
  color: var(--color-white);
}

.TabLink {
  color: var(--color-darker-grey);
  cursor: pointer;
}

.TabLink:hover {
  color: var(--color-primary);
}

.DisableAcountRow {
  background-color: #FFDFD8;
}

.DisableUserRow {
  pointer-events: none;
  /* opacity: 0.5; */
  background-color: #FFDFD8;
}

.DisableUserRow .RefreshIcon {
  pointer-events: auto !important;
  cursor: pointer;
}

/* .InlineFormContainer {
  border: 1px solid var(--border-color);
  background-color: #fbfbfb;
  border-radius: 8px;
  padding: 20px;
} */

.PopupBearerContent {
  word-break: break-all;
}

/* TooltipClass */
.TooltipClass {
  z-index: 5000 !important;
}

/* SelfHosting Classes */
.ProductDropDown {
  border-radius: 50px !important;
}
.MenuContainer {
  background-color: var(--color-primary);
  padding: 10px;
  border-radius: 10px;
}

.MenuItem {
  font-size: 16px;
  font-family: "GoogleSans";
  font-weight: 400;
  color: var(--color-white);
  background-color: transparent;
  cursor: pointer;
  padding: 10px 8px;
  border-radius: 6px;
  margin: 5px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.MenuItem:hover {
  background-color: var(--color-primary-dull-2);
  color: var(--color-primary);
}

.MenuActiveItem {
  background-color: var(--color-primary-dull);
  color: var(--color-primary);
}

.ActiveLicense {
  font-size: 12px;
  background-color: var(--success-text-dull);
  color: var(--success-text);
  padding: 3px 5px;
  border-radius: 4px;
  font-family: "Roboto-Medium";
}

.DeletedLicense {
  font-size: 12px;
  background-color: var(--error-text-dull);
  color: var(--error-text);
  padding: 3px 5px;
  border-radius: 4px;
  font-family: "Roboto-Medium";
}

.LicenseeDetailsContainer h3 {
  font-family: "GoogleSans";
  font-weight: 400;
  font-size: 24px;
}

.LicenseeDataContainer,
.LicenseeKeyContainer {
  border: 1px solid var(--border-color);
  padding: 20px;
  border-radius: 10px;
}

.LicenseeDataContainer label {
  font-family: "Roboto-Medium";
  font-weight: 500;
}

.Container {
  display: flex;
  position: relative;
}
.Progress {
  background-color: var(--color-medium-back);
  border-radius: 8px;
  width: 100%;
  height: 20px;
}
.ProgressBar {
  background-color: var(--color-primary) !important;
}
.ValueText {
  font-family: "GoogleSans";
  position: absolute;
  font-size: 12px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--color-white);
}

.LicenseeKeyContainer h4 {
  font-family: "Roboto-Medium";
  font-weight: 500;
  font-size: 16px;
}

.LicenseeActionIcon {
  cursor: pointer;
  background-color: var(--color-primary-dull);
  width: 28px;
  height: 28px;
  padding: 5px;
  border-radius: 4px;
}

.LicenseeActionDisabledIcon {
  pointer-events: none !important;
  cursor: default;
}

@media only screen and (max-width: 768px) {
  .Tabs {
    padding-left: 20px;
  }

  .Tab,
  .TabActive {
    font-size: 16px;
  }

  .ReportDataHead {
    font-size: 24px;
  }

  .ReportDataValue {
    font-size: 24px;
  }

  .ModalHead {
    color: var(--color-dark-blue);
    font-size: 24px;
    font-weight: 600;
  }

  .ModalIcon {
    cursor: pointer;
    width: 30px;
    height: 30px;
  }

  .ModalTable {
    background: #fbfbfb 0% 0% no-repeat padding-box;
    border: 1px solid #e1e1e1;
    color: var(--color-grey);
  }

  .ModalTable td {
    padding: 10px 20px;
    font-size: 14px;
  }

  .ChartTitle {
    font-size: 16px;
  }

}

@media only screen and (min-width: 768px) {
  .Tabs {
    padding-left: 30px;
  }

  .Tab,
  .TabActive {
    font-size: 18px;
  }

  .AccountCreation,
  .SuccessScreenPopup {
    max-height: 65vh;
    overflow-y: auto;
  }
}

@media only screen and (min-width: 992px) {
  .Tabs {
    padding-left: 30px;
  }

  .Tab,
  .TabActive {
    font-size: 18px;
  }
}