.Pagging {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  justify-content: center;
}
.PageDots {
  margin: 0px 13px;
  border: 1px solid var(--border-color);
  border-radius: 50px;
  width: 10px;
  height: 10px;
  background-color: var(--color-white);
  cursor: pointer;
}
.PageActiveDots {
  background-image: linear-gradient(90deg, var(--color-primary) 0%, var(--color-secondary) 100%);
  transform: scale(1.6);
  border: none;
}
.CarouselButtons {
  display: inline-flex;
  align-items: center;
  background-color: var(--color-white);
  font-weight: bold;
  height: 25px;
  width: 25px;
  padding: 5px;
  margin: auto 0px;
  border-radius: 50px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05);
  transition: 0.3s;
}
.CarouselButtons:hover{
  background-color: var(--color-primary);
  color: var(--color-white);
  transform: scale(1.3);
  border: 1px solid var(--color-primary);
}
.CarouselButtons:active{
  transform: scale(0.9);
}