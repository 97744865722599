.ModalContainer {
  display: flex;
  flex-direction: column;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #00000029;
  /* border: 1px solid #707070; */
  border-radius: 15px;
  opacity: 1;
  padding: 16px;
  max-height: 100vh;
  overflow-y: auto;
}
.ModalTitle {
  font-family: "Roboto-Medium";
  color: var(--color-dark-grey);
  /* font-weight: 600; */
  font-size: 28px;
  flex-grow: 1;
}
.ModalDraggableTitle {
  cursor: move !important;
  word-break: keep-all;
  text-overflow: ellipsis;
}
.CloseButton {
  cursor: pointer;
  width: 24px;
  height: 24px;
}
.ModalDraggableContentContainer{
  width:350px;
  height: 350px;
  margin: 10px 0px;
  border-top: 1px solid var(--border-color);
}
.FormModalContainer {
  display: flex;
  flex-direction: column;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #00000029;
  /* border: 1px solid #707070; */
  border-radius: 8px;
  opacity: 1;
  padding: 16px;
  max-height: 100vh;
  overflow-y: auto;
}

@media only screen and (max-width: 576px) {
  .ModalTitle {
    font-size: 24px;
  }
  .FormModalContainer {
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    border-radius: 0px;
    padding: 35px 16px;
  }
}
