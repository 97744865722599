.Card{
    border-radius: 6px;
    border-color: var(--border-color);
}
.CardImageContainer {
    width: 100%;
    height: 150px;
}
.CardImage {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.CardTitle {
    font-size: var(--card-title-label);
    color: var(--color-dark-blue);
    font-weight: bold;
    letter-spacing: 0.3px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.CardDesc {
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    color: var(--color-grey);
}
.CardAvailable {
    font-family: "Roboto-Medium";
    font-size: 15px;
    color: var(--color-dark-grey);
    margin-top: 5px;
}
.CardActive {
    font-family: "Roboto-Medium";
    font-size: 12px;
    padding: 3px 8px;
    border-radius: 50px;
    color: var(--color-white);
    background-color: var(--color-active);
}
.CardInActive {
    font-family: "Roboto-Medium";
    font-size: 12px;
    padding: 3px 8px;
    border-radius: 50px;
    color: var(--color-grey);
    /* background-color: var(--color-expired); */
    background-color: #e2e2e2;
}
.CardForward {
    font-size: 14px;
    color: var(--color-orange);
    width: 100%;
    text-align: end;
}