.NavBar {
  /* background:#FFA500; */
  /* border: 1px solid; */
  /* padding: 10px; */
  box-shadow: 1px 1px 10px #cec4c4;
  background: #ffffff 0% 0% no-repeat padding-box;
  /* padding: 20px; */
}
.LogoContainer {
  height: 57px;
  padding: 8px 0px;
}
.Logo {
  height: 100%;
  object-fit: contain;
}

.NavItem {
  opacity: 1;
  text-decoration: none;
  font-family: "GoogleSans";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.3px;
  color: var(--color-darker-blue);
}

.NavItem:hover {
  color: var(--nav-text-color);
  opacity: 0.6;
  text-decoration: none;
}

.NavDropDown {
  background-color: transparent !important;
  border: none !important;
  padding: 6px 0px !important;
  color: var(--color-darker-blue);
  outline: none !important;
  box-shadow: none !important;
  font-family: "GoogleSans";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.3px;
}

.NavDropDown:hover,
.NavDropDown:active {
  color: var(--color-darker-blue);
  opacity: 0.6;
}

.NavDropDownMenu {
  margin-top: 0px !important;
  z-index: 1;
  border: none;
  box-shadow: 0px 10px 20px #00000029;
  padding: 10px;
  width: 250px;
}

.NavDropDownMenuItem {
  border-radius: 4px;
  margin: 2px 0px;
  padding: 10px;
  transition: 0.3s;
}
.NavDropDownMenuItem h3 {
  cursor: pointer;
  font-size: 15px;
  font-family: "GoogleSans";
  font-weight: 600;
  margin-bottom: 0px;
  color: var(--color-darker-blue);
}
.NavDropDownMenuItem p {
  font-size: 10px;
  font-family: "GoogleSans";
  margin-bottom: 0px;
  overflow: hidden;
  white-space: pre-wrap !important;
  color: var(--color-darker-grey);
}
.NavDropDownMenuItem img {
  width: 20px;
  height: 20px;
}

.NavDropDownMenuItem:hover h3 {
  color: var(--color-primary);
}

.NavDropDownMenuItem:hover {
  background-color: var(--color-light-back);
}

.NavLink {
  color: var(--color-orange);
  cursor: pointer;
}

.NavLink:hover {
  background-color: var(--color-orange);
  color: var(--color-white);
}

.SingleNotificationContainer {
  cursor: pointer;
  font-size: 12px;
  border-bottom: 1px solid var(--border-color);
  padding: 0.5rem 5px;
  display: flex;
  flex-direction: column;
}

.SingleNotificationContainer:hover {
  background-color: var(--model-group-bg-color);
}

/* .NavBar a{ */
/* color:#FFA500; */
/* text-decoration: none;
    font-weight: bolder;
    font-size: 18px;
    color: var(--color-active-orange); */
/* background-image: linear-gradient(90deg, #FF00B7 0%, #FF7F5E 100%);
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent; */
/* } */
.PrimaryButton {
  border: 1px solid #d2d2d2;
  padding: 10px 25px 10px 25px;
  border-radius: 4px;
}
.PrimaryButton:hover {
  color: #ffffff;
  background-color: #ff7f5e;
  /* border: none; */
}
.SecondaryButton {
  background: transparent linear-gradient(90deg, #ff00b7 0%, #ff7f5e 100%) 0% 0%
    no-repeat padding-box !important;
  color: #ffffff !important;
  border: none !important;
}
.SecondaryButton,
.AuthButton {
  border-radius: 6px;
  opacity: 1;
  padding: 6px 25px !important;
  font-size: 16px !important;
  /* border: none !important; */
}
.SecondaryButton:hover {
  border: none !important;
}
.AuthLink {
  font-family: "Roboto-Medium";
  font-size: var(--nav-text-size);
  color: var(--nav-text-color);
  opacity: 1;
  text-decoration: none;
  cursor: pointer;
}
/* 
.Button {
  height: 40px;
  color: #ff7c5f !important;
  font-size: 16px !important;
  border-radius: 6px;
  background: #ffffff 0% 0% no-repeat padding-box;
  padding: 10px 25px 10px 25px;
  border: 1px solid #d2d2d2;
  vertical-align: middle;
}
.Button:hover {
  height: 40px;
  background: #ff7c5f !important;
  font-size: 16px !important;
  border-radius: 6px;
  color: #ffffff !important;
  padding: 10px 25px 10px 25px;
  border: 1px solid #ff7c5f;
} */

.LoadingContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.8) 0% 0% no-repeat padding-box;
  z-index: 2;
}
.MenuItemsContainer {
  position: fixed;
  width: 40vw;
  height: 100%;
  right: 0;
  margin-top: 10px;
  z-index: 1;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 10px 50px;
  overflow-y: auto;
}
.MenuItemsContainer li {
  list-style-type: none;
  margin: 10px 0px;
}
.MenuItemsContainer .NavItem {
  font-size: 18px !important;
  width: 100%;
}
.MenuItemsContainer .NavDropDown {
  font-size: 18px !important;
}
.MenuItemsContainer .SecondaryButton,
.MenuItemsContainer .Button {
  font-size: 18px !important;
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .MenuItemsContainer {
    width: 100vw;
  }
}
  
@keyframes MoveDown {
  from {
    opacity: 0.8;
    margin-top: -10px;
  }
  to {
    opacity: 1;
    margin-top: 20px;
  }
}