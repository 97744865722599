.Card {
  border-radius: 5px;
  box-shadow: 2px 2px 10px #cec4c4;
  border: 0;
  align-items: center;
}

.Button {
  border-radius: 50px;
  color: #ffffff !important;
  background-color: #ffa500;
  font-size: 14px !important;
  text-align: center;
  width: 150px;
  margin-top: 15px;
}

.Title {
  align-self: flex-start;
  padding-left: 30px;
}

.ProfileValues {
  background-color: transparent;
  color: var(--color-darker-blue);
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  white-space: nowrap;
  text-align: left;
  border-radius: 6px;
  text-decoration: none;
}
.ProfileValues:hover {
  background-color: var(--bg-color);
}
.ProfileValues img {
  width: 18px;
  height: auto;
  margin-right: 10px;
}

.User {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  color: #8f8f8f;
  display: block;
  padding: 5px;
  background-color: #fbfbfb;
}

.ProfileDopDown {
  position: absolute;
  right: 0;
  margin-top: 24px;
  z-index: 1;
  width: 300px;
  animation-name: MoveDown;
  animation-duration: 0.3s;
  animation-iteration-count: 1;
}

.SubscriptionDiamond {
  height: 20px !important; 
  width: 20px !important; 
  border-radius: 10rem;
  position: absolute;
  bottom: 0px;
  /* right: 80%; */
  left: 28px;
  padding: 2px;
}

@keyframes MoveDown {
  from {
    opacity: 0.8;
    margin-top: 10px;
  }
  to {
    opacity: 1;
    margin-top: 24px;
  }
}

@media only screen and (max-width: 768px) {
  
.ProfileValues {
  font-size: 14px;
}
.ProfileValues img {
  width: 16px;
  height: auto;
  margin-right: 8px;
}
.ProfileDopDown {
  width: 250px;
}
}

@media only screen and (max-width: 991px) {
  .SubscriptionDiamond {
    right: 30%;
  }
}