.MarketingLayout {
  background-image: url("../../assets/images/banner/banner-background.gif");
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: inherit;
  background-size: auto 100%;
  background-color: var(--color-primary-dark);
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 60px 60px 0 60px;
  text-align: center;
}

.MarketingHeading {
  letter-spacing: 1px;
  line-height: 48px;
}

.MarketingText {
  color: #ffffffd9;
  opacity: 0.87;
  margin-top: 15px;
}

.MarketingCopyrightText {
  opacity: 0.6;
}

.MarketingImageCard {
  padding: 10px 20px;
}

.MerketingImage {
  /* height: 250px; */
  width: 240px;
  border-radius: 24px;
}

.PageDot {
  margin: 0px 3px;
  border: 1px solid #d2d2d2;
  border-radius: 50px;
  width: 7px;
  height: 7px;
  background-color: #ffffff;
  cursor: pointer;
  opacity: 0.5;
}

.PageDotActive {
  /* background-image: linear-gradient(90deg, #ff00b7 0%, #ff7f5e 100%);
    transform: scale(1.6);
    border: none; */
  opacity: 1;
}

@media only screen and (min-width: 768px) {
  .MarketingHeading {
    font-size: 36px;
  }
  .MarketingText {
    font-size: 14px;
  }
  .MerketingImage {
    width: 160px;
  }
}

@media only screen and (min-width: 992px) {
  .MarketingHeading {
    font-size: 42px;
  }
  .MerketingImage {
    width: 180px;
  }
}

@media only screen and (min-width: 1200px) {
  .MarketingHeading {
    font-size: 46px;
  }
  .MerketingImage {
    width: 240px;
  }
}
