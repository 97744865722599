.Container {
  padding-top: 115px;
  padding-bottom: 100px;
}
.HeadingText {
  font-size: 36px !important;
  text-align: center;
}
.SubText {
  font-size: 18px !important;
  text-align: center;
  margin-top: 10px;
}
.Image {
  width: 350px;
  height: auto;
}
@media only screen and (max-width: 768px) {
  .Container {
    padding: 60px 15px;
  }
  .HeadingText {
    font-size: 20px !important;
  }
  .SubText {
    font-size: 14px !important;
  }
  .Image {
    width: 300px;
    height: auto;
  }
}
@media only screen and (min-width: 768px) {
  .Container {
    padding: 80px 0px;
  }
  .HeadingText {
    font-size: 32px !important;
  }
  .SubText {
    font-size: 16px !important;
  }
  .Image {
    width: 350px;
    height: auto;
  }
}
@media only screen and (min-width: 992px) {
  .Container {
    padding: 100px 0px;
  }
  .HeadingText {
    font-size: 36px !important;
  }
  .SubText {
    font-size: 18px !important;
  }
  .Image {
    width: 350px;
    height: auto;
  }
}
