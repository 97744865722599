.Footer {
  background-color: #ffffff;
  padding: 20px 0px 20px 0px;
  /* width: 100%; */
  overflow-x: hidden;
}
.Footer hr , .FooterTopLine{
  color: grey;
}
.FooterTopLine {
  margin: 0;
  margin-top: 35px;
  border: 0px;
}
.FooterAddress{
  white-space: pre-wrap;
}

.Footer a {
  text-decoration: none;
  opacity: 1;
}

.FooterContent {
  align-items: flex-start;
  color: var(--color-dark-text) !important;
  display: flex;
  justify-content: space-between;
}

.FontWeight{
  font-weight: 600;
}

.FooterLogo{
  filter: grayscale();
}

.FooterTitleText {
  font-family: "GoogleSans";
  /* color: var(--footer-title-text); */
  font-size: 22px;
  padding-bottom: 5px;
  letter-spacing: 0.5px;
}
.FooterTitleText img {
  height: 57px;
  width: auto;
}
.FooterCopyRightText {
  /* opacity: 0.6; */
  font-size: 14px;
  margin-top: 15px;
  /* padding: 20px 0px; */
  color: #333333;
  margin-bottom: 0;
}
.FooterContentText {
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0px;
  color: var(--color-dark-text);
  opacity: 1;
  padding-top: 12px;
}

.FooterText {
  font-size: 16px;
  margin-bottom: 0;
  color: var(--color-darker-grey);
  padding-top: 12px;
}

/* .FooterText a {
  color: var(--color-darker-grey) !important;
} */

.AdditionalFooterIcon {
  display: block;
}

.AdditionalFooterIcon img {
  width: auto;
  height: 30px;
  margin: 0px;
}

.FooterIcon {
  background: #e9e9e9;
  border-radius: 8px;
  display: inline-flex;
  padding: 8px;
  margin-left: 12px;
  cursor: pointer;
  color: var(--color-medium-text);
  transition: 0.1s;
}

.FooterIconFaceBook:hover {
  background: #316FF6;
  color: white !important;
}

.FooterIconXTwitter:hover {
  background: #000000;
  color: white !important;
}

.FooterIconInstagram:hover {
  background: #f09433; 
  background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); 
  background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
  background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
  color: white !important;
}

.FooterIconLinkedIn:hover {
  background: #0077B5; 
  color: white !important;
}

.FooterIconYouTube:hover {
  background: #CD201F; 
  color: white !important;
}

@media only screen and (max-width: 768px) {
  .FooterContent {
    padding-right: 30px;
    padding-left: 30px;
  }
  .FooterContentText {
    font-size: 14px;
  }
  .FooterTitleText {
    font-size: 24px;
  }
  .AdditionalFooterIcon,.FooterIcon {
    margin: 4px;
  }

}

@media only screen and (min-width: 768px) {
  .FooterContent {
    padding-right: 60px;
    padding-left: 60px;
  }
  .FooterContentText {
    font-size: 16px;
  }
  .FooterTitleText {
    font-size: 20px;
  }
  .FooterIcon {
    margin-left: 12px;
  }
}

@media only screen and (min-width: 992px) {
  .FooterContent {
    padding-right: 100px;
    padding-left: 100px;
  }
  .FooterTitleText {
    font-size: 22px;
  }
}

.FooterBetaContent {
  color: var(--color-dark-text) !important;
  text-align: center;
  background: #F6F6F6 0% 0% no-repeat padding-box;
  opacity: 1;
  padding: 15px;
}
.FooterBetaContent span{
  color: var(--color-orange);
}
.FooterBetaContent span:hover{
  color: var(--color-active-orange);
}